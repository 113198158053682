var Swiper = require('swiper');
var q = require('queried');

var datesSliderContainer = q('.swiper-container');

new Swiper (datesSliderContainer, {
	loop: true,
	nextButton: q('.swiper-button-next', datesSliderContainer),
	prevButton: q('.swiper-button-prev', datesSliderContainer)
});

/* Ganalytics */
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','//www.google-analytics.com/analytics.js','ga');

ga('create', 'UA-65830955-1', 'auto');
ga('send', 'pageview');

q.all('a[href*="fastlife.su"]').forEach(function(link) {
	link.addEventListener('click', function(e) {
		e.preventDefault();
		var url = link.href;

		ga('send', 'event', 'outbound', 'click', url, {
			transport: 'beacon',
			hitCallback: function() {document.location = url;}
		});
	});
});

(window.Image ? (new Image()) : document.createElement('img')).src = location.protocol +
    '//vk.com/rtrg?r=qC2BI4j7MhqmD*bXopr8CNGpsx*HhSNk0IEs8hAdztNPqo1y66DlVfRksunFRGdQ' +
    'cYIQRVWize92xLNP4F03Q4OElrT5bPMSInKGsswW4Kn9oO*YHrhqac62JIPSytUXqk52YWHo/XKhW3xb' +
    '5mVpFS*0WuNJqpuW/gr1gmaaWsk-&pixel_id=1000099494';